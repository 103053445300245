<template>
	<div class="screen">
		<div class="jg item" @click="sortChange(1)">
			{{i18n('76')}}
			<div class="">
				<img src="@/assets/upAc.png" alt="" v-if="params.sort==2">
				<img src="@/assets/up.png" alt="" v-else>
				<img src="@/assets/downAc.png" alt="" v-if="params.sort==1">
				<img src="@/assets/down.png" alt="" v-else>
			</div>
		</div>
		<div class="xl item" @click="sortChange(2)">
			{{i18n('136')}}
			<div class="">
				<img src="@/assets/upAc.png" alt="" v-if="params.sort==4">
				<img src="@/assets/up.png" alt="" v-else>
				<img src="@/assets/downAc.png" alt="" v-if="params.sort==3">
				<img src="@/assets/down.png" alt="" v-else>
			</div>
		</div>
		<div class="label">
			<div class="inner">
				<div class="left">
					<img src="@/assets/filter.png" alt="">
					<div class="seleData">
						{{i18n('137')}}
					</div>
				</div>
				
				<div class="right">
					<img src="@/assets/view-list.png" alt="">
				</div>
			</div>
			<div class="posi">
				<div class="list">
					<div :class="item.checked?'itemAc item':'item'" v-for="(item,index) in listData" :key="index" @click="seleLabel(index)">
						{{lang=='zh'?item.zh_name:lang=='en'?item.en_name:item.vi_name}}
					</div>
				</div>
				<div class="but">
					<div class="" @click="cz">
						{{i18n('138')}}
					</div>
					<div class="" @click="filterSub">
						{{i18n('56')}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {commodityLabel} from "@/api/commodity.js"
	import {
		getCookies,
		setCookies
	} from "@/utils/cookies.js"
	
	export default {
		name: "screen",
		data() {
			return {
				params:{
					sort:0,//0,默认排序，1:价格降序，2价格升序，3:销量降序，4：销量升序
					filter:[]
				},
				listData:[],
			}
		},
		async mounted() {
			if(!getCookies('commodityLabel')){
				let {data} = await commodityLabel()
				data.map(item=>{
					item.checked=false
				})
				this.listData=data
				setCookies('commodityLabel',data)
			}else{
				let data = JSON.parse(getCookies('commodityLabel')) 
				data.map(item=>{
					item.checked=false
				})
				this.listData=data
			}
		},
		methods: {
			sortChange(type){
				if(type==1){
					if(this.params.sort==2){
						this.params.sort = 1
					}else{
						this.params.sort = 2
					}
				}else{
					if(this.params.sort==4){
						this.params.sort = 3
					}else{
						this.params.sort = 4
					}
				}
				this.$emit('change',this.params)
			},
			seleLabel(index){
				this.listData[index].checked=!this.listData[index].checked
			},
			cz(){
				this.params={
					sort:0,//0,默认排序，1:价格降序，2价格升序，3:销量降序，4：销量升序
					filter:[]
				}
				this.listData.map((item=>{
					item.checked=false
				}))
				this.$emit('change',this.params)
				this.show=false
			},
			filterSub(){
				let filter = []
				this.listData.map((item=>{
					if(item.checked){
						filter.push(Number(item.id))
					}
				}))
				this.params.filter=filter
				this.$emit('change',this.params)
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
		}
	};
</script>

<style scoped>
	.posi{
		position: absolute;
		left: 0;
		top: 100%;
		width: 100%;
		background: #FFFFFF;
		box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.1);
		border-radius: 4px;
		z-index: 10;
		display: none;
	}
	.posi .list{
		display: flex;
		padding: 11px 3px 11px 11px;
		flex-wrap: wrap;
	}
	.posi .list .item{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		text-align: center;
		font-weight: 400;
		background: rgba(86,171,123,.1);
		border-radius: 4px;
		line-height: 24px;
		margin-right: 8px;
		padding: 0 18px;
		margin-bottom: 8px;
	}
	.posi .list .itemAc{
		background: rgba(86,171,123,1);
		color: #F8F8F8;
	}
	.posi .but{
		height: 50px;
		border-top: 1px solid rgba(241,241,241,1);
		padding: 0 9px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
	.posi .but div:nth-child(1){
		width: 64px;
		height: 32px;
		border: 0.5px solid rgba(86,171,123,1);
		border-radius: 20px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #56AB7B;
		text-align: center;
		font-weight: 500;
		line-height: 32px;
	}
	.posi .but div:nth-child(2){
		width: 64px;
		height: 32px;
		background: #56AB7B;
		border-radius: 20px;
		font-family: PingFangSC-Medium;
		font-size: 14px;
		color: #F8F8F8;
		text-align: center;
		font-weight: 500;
		line-height: 32px;
	}
	.label{
		height: 46px;
		display: flex;
		align-items: center;
		position: relative;
		cursor: pointer;
	}
	.label:hover .posi{
		display: block;
	}
	.label .inner{
		display: flex;
		align-items: center;
	}
	.label .inner .left{
		padding: 0 9px;
		height: 36px;
		background: #FFFFFF;
		border-radius: 4px 0px 0px 4px;
		display: flex;
		align-items: center;
		min-width: 120px;
	}
	.label .inner .left img{
		width: 16px;
		height: 16px;
		margin-right: 10px;
		display: block;
	}
	.label .inner .left div{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 22px;
		font-weight: 400;
		opacity: .3;
	}
	.label .inner .left div.ac{
		opacity: 1;
	}
	.label .inner .right{
		width: 28px;
		height: 36px;
		background: #FFFFFF;
		border-radius: 0px 4px 4px 0px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: 1px;
	}
	.label .inner .right img{
		width: 12px;
		height: 12px;
		display: block;
	}
	.screen{
		display: flex;
		align-items: center;
	}
	.screen .item{
		height: 36px;
		background: #FFFFFF;
		border-radius: 4px;
		padding-left: 14px;
		display: flex;
		align-items: center;
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #1D2129;
		line-height: 22px;
		font-weight: 400;
		margin-right: 12px;
		cursor: pointer;
	}
	.screen .item div{
		padding: 0 6px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}
	.screen .item div img{
		width: 13px;
		height: 13px;
		display: block;
	}
</style>