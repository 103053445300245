<template>
	<div class="classList">
		<div class="zw">

		</div>
		<div class="title">
			<span v-if='categoryType==1'>{{i18n('83')}}</span>
			<span v-if='categoryType==2'>{{i18n('84')}}</span>
			<span v-if='categoryType==3'>{{i18n('85')}}</span>
			<span v-if='categoryType==10'>{{i18n('135')}}</span>
			<span v-if='categoryType==0'>
				{{lang=='zh'?seleCategory.base.zh_name:lang=='en'?seleCategory.base.en_name:seleCategory.base.vi_name}}
			</span>
		</div>
		<div class="list" v-if="categoryType==0">
			<div :class="item.id==seleCategory.childrenId?'item itemAc':'item'"
				v-for="(item,index) in seleCategory.children" :key="index" @click="seleCategoryChange(item.id)">
				<div class="nr over2">
					{{lang=='zh'?item.zh_name:lang=='en'?item.en_name:item.vi_name}}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "classList",
		components: {

		},
		data() {
			return {

			}
		},
		methods: {
			seleCategoryChange(id) {
				this.$store.commit("user/setSeleCategory", {
					childrenId: id
				})
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
			seleCategory() {
				console.log("this.$store.state.user.seleCategory")
				console.log(this.$store.state.user.seleCategory)
				return this.$store.state.user.seleCategory
			},
			categoryType() {
				return this.$store.state.user.categoryType
			}
		},
		created() {

		},
		mounted() {

		}
	};
</script>

<style scoped>
	.classList {
		display: flex;
		flex-direction: column;
	}

	.zw {
		height: 15px;
	}

	.title {
		width: 100%;
		height: 55px;
		background: #fff;
		text-align: center;
		line-height: 55px;
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #1D2129;
		font-weight: 500;
	}
	.list{
		flex: 1;
		overflow-y: scroll;
		width: 100%;
	}
	.list .item{
		width: 130px;
		min-height: 36px;
		display: flex;
		align-items: center;
		justify-content: center;
		margin: 0 auto;
		margin-bottom: 4px;
	}
	.list .item .nr{
		padding: 0 17px;
		line-height: 24px;
		background: #fff;
		border-radius: 12px;
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #4E5969;
		text-align: center;
		line-height: 22px;
		font-weight: 400;
		cursor: pointer;
	}
	.list .itemAc .nr{
		background: #56AB7B;
		color: #fff;
	}
</style>
