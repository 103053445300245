<template>
	<div class="category">
		<div class="left">
			<classList></classList>
		</div>
		<div class="right">
			<div class="top">
				<topLabel></topLabel>
				<screen @change="screenChange" v-if="categoryType==0"></screen>
			</div>
			<div class="list" ref="scollElement">
				<div class="noData" v-if="noData">
					<div class="">
						<img src="@/assets/noData2.png" alt="">
						<div class="text">
							{{i18n('140')}}
						</div>
					</div>
				</div>
				<div class="tj" v-if="noData && categoryType==10">
					{{i18n('141')}}
				</div>
				<div class="item" v-for="(item,index) in listData" :key="index">
					<commodity :data='item'></commodity>
				</div>
				<div class="pagination" v-if="total!=0 && !noData">
					<el-pagination layout="prev, pager, next" :total="total" :page-size="20" :current-page='commodityHomeParams.page_num'
						@current-change="currentChange">
					</el-pagination>
				</div>
				
			</div>
		</div>
	</div>
</template>

<script>
	import {
		commodityHome,
		commodityList
	} from "@/api/commodity.js"
	import classList from "./components/classList.vue"
	import topLabel from "./components/topLabel.vue"
	import screen from "./components/screen.vue"
	import commodity from "@/components/commodity.vue"
	export default {
		name: "category",
		components: {
			classList,
			topLabel,
			commodity,
			screen
		},
		data() {
			return {
				listData: [],
				commodityHomeParams: {
					type: 1, //1精选好货  //2特价商品  //3预定商品
					page_num: 1,
					page_size: 20
				},
				total: 0,
				params: {
					page_num: 1,
					page_size: 20,
					name: "",
					mix_stock: -1,
					max_stock: -1,
					category_id: 0,
					status: 0,
					sort: 0, //0,默认排序，1:价格降序，2价格升序，3:销量降序，4：销量升序
					// filter: [],  //筛选的Label ID
					isRecommend: -1 //0普通，1 分类推荐,2 全部（英文版本），如果是1,2，category_id传父级ID
				},
				noData:false
			}
		},
		methods: {
			//初始化
			init() {
				this.commodityHomeParams.type = this.categoryType
				this.commodityHomeParams.page_num = 1
				this.params.page_num = 1
				if (this.categoryType != 0 && this.categoryType != 10) {
					this.commodityHome()
				} else {
					if(this.categoryType == 10){
						this.params.name = this.$store.state.user.searchData
						this.params.sort = 0
						this.params.filter = []
					}
					//获取分类商品
					this.commodityList()
				}
			},
			//获取推荐商品列表
			async commodityHome() {
				if(this.categoryType==10){
					this.commodityHomeParams.type = 1
				}
				let {
					data,
					total
				} = await commodityHome(this.commodityHomeParams)
				this.listData = data
				this.total = total
				this.$refs.scollElement.scrollTop = 0;
				if(this.categoryType!=10){
					if(total==0){
						this.noData = true
					}else{
						this.noData = false
					}
				}
			},
			//页码数变化
			currentChange(page_num) {
				if (this.commodityHomeParams.type != 0 && this.commodityHomeParams.type != 10) {
					this.commodityHomeParams.page_num = page_num
					this.commodityHome()
				}else{
					this.params.page_num = page_num
					this.commodityList()
				}
			},
			async commodityList() {
				
				if(this.categoryType != 10){
					this.params.category_id = this.seleCategory.childrenId
				}else{
					this.params.category_id = 0
				}
				let {
					data,
					total
				} = await commodityList(this.params)
				this.listData = data
				this.total = total
				this.$refs.scollElement.scrollTop = 0;
				if(total==0 && this.categoryType==10){
					this.noData = true
					this.commodityHome()
				}else{
					if(total==0){
						this.noData = true
					}else{
						this.noData = false
					}
				}
			},
			screenChange(params){
				this.params.sort = params.sort
				this.params.filter = params.filter
				this.params.page_num = 1
				this.commodityList()
			}
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
			categoryType() {
				return this.$store.state.user.categoryType
			},
			seleCategory() {
				return this.$store.state.user.seleCategory
			},
			searchData() {
				return this.$store.state.user.searchData
			},
		},
		watch: {
			categoryType() {
				if (this.categoryType != this.commodityHomeParams.type) {
					this.params.name=''
					this.init()
				}
			},
			'seleCategory.childrenId'() {
				this.params.name=''
				this.init()
			},
			searchData() {
				this.params.category_id = 0
				this.init()
			}
		},
		created() {
			console.log('是这里')
			this.init()
		},
		mounted() {

		}
	};
</script>

<style scoped>
	.tj{
		font-family: PingFangSC-Medium;
		font-size: 20px;
		color: #1D2129;
		line-height: 28px;
		font-weight: 500;
		margin-bottom: 24px;
		width: 100%;
	}
	.noData{
		width: 100%;
		height: 200px;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.noData img{
		width: 64px;
		height: 64px;
		display: block;
		margin: 0 auto;
	}
	.noData .text{
		font-family: PingFangSC-Regular;
		font-size: 12px;
		color: #86909C;
		line-height: 20px;
		font-weight: 400;
		text-align: center;
		margin-top: 10px;
	}
	>>>.el-pager li {
		background: none;
	}

	>>>.el-pagination .btn-next {
		background: none;
	}

	>>>.el-pagination button:disabled {
		background: none;
	}

	>>>.el-pagination .btn-prev {
		background: none;
	}

	>>>.el-pager li.active {
		color: #56AB7B;
	}

	.pagination {
		width: 100%;
		height: 36px;
		padding-bottom: 24px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.category {
		display: flex;
		width: 100%;
		height: 100%;
	}

	.left {
		width: 220px;
		height: 100%;
		/* background: #FFFFFF; */
	}

	.right {
		flex: 1;
		height: 100%;
		width: 100%;
		padding-left: 24px;
		background: #F5F5F5;
		box-sizing: border-box;
		display: flex;
		flex-direction: column;
	}

	.right .top {
		height: 70px;
		width: 955px;
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.right .list {
		flex: 1;
		width: 955px;
		overflow-y: scroll;
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}

	.right .list .item {
		margin-bottom: 16px;
		margin-right: 22px;
	}

	.right .list .item:nth-child(4n) {
		margin-right: 0;
	}
</style>
