<template>
	<div class="topLabel">
		<div class="sy" @click="$router.push('/')">
			{{i18n('02')}}
		</div>
		<span>/</span>
		<div v-if="categoryType==1" class="it">{{i18n('83')}}</div>
		<div v-if="categoryType==2" class="it">{{i18n('84')}}</div>
		<div v-if="categoryType==3" class="it">{{i18n('85')}}</div>
		<div v-if="categoryType==10" class="it">{{i18n('139')}}</div>
		<div v-if="categoryType==0" class="it">{{lang=='zh'?seleCategory.base.zh_name:lang=='en'?seleCategory.base.en_name:seleCategory.base.vi_name}}</div>
		<span v-if="categoryType==0">/</span>
		<div v-if="categoryType==0" class="it">{{lang=='zh'?seleCategoryChildren.zh_name:lang=='en'?seleCategoryChildren.en_name:seleCategoryChildren.vi_name}}</div>
	</div>
</template>

<script>
	export default {
		name: "topLabel",
		components: {
	
		},
		data() {
			return {
	
			}
		},
		methods: {
			
		},
		computed: {
			lang() {
				return this.$store.state.i18n.lang
			},
			seleCategory() {
				return this.$store.state.user.seleCategory
			},
			seleCategoryChildren(){
				let data = false
				let seleCategory = this.$store.state.user.seleCategory
				if(seleCategory){
					seleCategory.children.map(item=>{
						if(item.id==seleCategory.childrenId){
							data = item
						}
					})
				}
				return data
			},
			categoryType() {
				return this.$store.state.user.categoryType
			}
		}
	};
</script>

<style scoped>
	.topLabel{
		display: flex;
		align-items: center;
	}
	.topLabel .sy{
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #4E5969;
		line-height: 22px;
		font-weight: 400;
		cursor: pointer;
	}
	.topLabel span{
		font-family: PingFangSC-Regular;
		font-size: 14px;
		color: #4E5969;
		line-height: 22px;
		font-weight: 400;
		opacity: .8;
		padding: 0 4px;
	}
	.topLabel .it{
		font-family: PingFangSC-Regular;
		font-size: 16px;
		color: #1D2129;
		line-height: 22px;
		font-weight: 400;
	}
</style>